import { GetServerSidePropsContext } from 'next';
import nookies from 'nookies';

import { LoginTemplate } from '@/features/auth/template/login';
//ログイン画面

const Login = () => <LoginTemplate />;

export default Login;

export const getServerSideProps = async (ctx: GetServerSidePropsContext) => {
  // 全てのクッキーを取得
  const allCookies = nookies.get(ctx);

  // 全てのクッキーをループして削除
  // eslint-disable-next-line no-restricted-syntax, guard-for-in
  for (const cookieName in allCookies) {
    nookies.destroy(ctx, cookieName);
  }
  return { props: {} };
};
