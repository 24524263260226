import { FC } from 'react';

import { Group } from '@mantine/core';

type LayoutProps = {
  children: React.ReactNode;
};

export const Layout: FC<LayoutProps> = ({ children }) => (
  <main style={{ background: 'white' }}>
    <Group position="center" mt={200}>
      {children}
    </Group>
  </main>
);
