import { useEffect } from 'react';

import { notifications } from '@mantine/notifications';
import { useSearchParams } from 'next/navigation';

import { Layout } from '../components/Layout';
import { LoginForm } from '../components/LoginForm';

import { AUTH_FAILURE, QUERY_STRING } from '@/utils/constant';

export const LoginTemplate = () => {
  const params = useSearchParams();
  const authFailureType = params.get(QUERY_STRING.AUTH_FAILURE_TYPE);
  useEffect(() => {
    switch (authFailureType) {
      case AUTH_FAILURE.UNAUTHED:
        notifications.show({
          title: '認証失敗',
          message: 'ログインする必要があります',
          color: 'red',
        });
        break;
      case AUTH_FAILURE.UNAUTHORIZED:
        notifications.show({
          title: '認可失敗',
          message: '権限がありません',
          color: 'red',
        });
        break;
      case AUTH_FAILURE.UNAUTHORIZED_SITE:
        notifications.show({
          title: '認可失敗',
          message: '工事の権限がありません',
          color: 'red',
        });
        break;
    }
  }, []);

  useEffect(() => {
    if (typeof window === 'undefined') return;
    //LocalStorageをclearする
    localStorage.clear();
    //Cookieをclearする
    const cookies = document.cookie.split(';');
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = `${name}=;max-age=0`;
    }
  }, []);
  return (
    <Layout>
      <LoginForm />
    </Layout>
  );
};
