import { useEffect } from 'react';

import { Button, Center, Divider, Group, Stack, Text } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import Image from 'next/image';
import { useSearchParams } from 'next/navigation';

import {
  signIn,
  signInForTestCompany,
  signInToDummyCommonUser,
  signInToDummySysAdminUser,
  signInToDummyUser,
} from '@/lib/auth/auth';
import { CLIENT_ENV } from '@/utils/constant';

export const LoginForm = () => {
  const searchParams = useSearchParams();

  const companyCode = searchParams.get('companyCode');
  useEffect(() => {
    if (!companyCode) {
      notifications.show({
        title: '注意',
        message: '企業コードがURLに設定されていません',
        color: 'yellow',
      });
    }
  }, [companyCode]);
  return (
    <Stack align="center" spacing={80}>
      <Center w={200} h={200} bg="primary">
        <Stack spacing="24px" align="center">
          <Image width={80} height={13} src="/images/company-logo.svg" alt="会社ロゴ" />
          <Image width={140} height={31} src="/images/application-logo.svg" alt="アプリロゴ" />
        </Stack>
      </Center>
      <Group position="apart">
        <Stack spacing={10}>
          <Text fw={600} fs="sm">
            トプコンIDをお持ちの方
          </Text>
          {/*MEMO: 本当はfederationSignInもuseAuthenticatorフックから行いたいが、やり方がよくわからない */}
          <Button radius="xl" onClick={() => signIn(companyCode)}>
            トプコンIDでログイン
          </Button>

          {CLIENT_ENV !== 'production' && (
            <>
              <Divider />

              <Text fw={600} fs="sm">
                開発者向けテストログイン
                <br />
                (本番環境では表示されません)
              </Text>
              <Button variant="outline" radius="xl" onClick={signInForTestCompany}>
                テスト企業でログイン(TOPCON認証)
              </Button>
              <Button variant="outline" radius="xl" onClick={signInToDummyCommonUser}>
                テスト企業に一般ユーザーでログイン(認証無視)
              </Button>

              <Button variant="outline" radius="xl" onClick={signInToDummyUser}>
                テスト企業に所有者ユーザーでログイン(認証無視)
              </Button>

              <Button mb={20} variant="outline" radius="xl" onClick={signInToDummySysAdminUser}>
                システム管理者でログイン(認証無視)
              </Button>
            </>
          )}
        </Stack>
      </Group>
    </Stack>
  );
};
